/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:33 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-We 04:11:52
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";
let resquest2 = "/api/api";

// 获取分类
export async function queryArtGalleryCategory(params) {
  let res = await http.get(`${resquest}/Art/QueryArtGalleryCategory?parentId=` + params)
  return res.data;
}

// 获取分类
export async function queryNewsCategoryAndNews(params) {
  let res = await http.get(`${resquest}/Art/QueryNewsCaregoryAndNews` + params)
  return res.data;
}

// 根据分类获取资讯
export async function queryArtGalleryNews(params) {
  let res = await http.post(`${resquest}/Art/QueryArtGalleryNews`, params)
  return res.data;
}

// 三合美术馆简介
export async function queryArtGallery(params) {
  let res = await http.get(`${resquest}/Art/QueryArtGallery`, params)
  return res.data;
}

// 专家团队
export async function queryArtist(params) {
  let res = await http.post(`${resquest}/Art/QueryArtist`, params)
  return res.data;
}

// 专家详情
export async function queryArtistDetail(params) {
  let res = await http.get(`${resquest}/Art/QueryArtistDetail` + params)
  return res.data;
}

// 专家作品
export async function queryArtistProduce(params) {
  let res = await http.get(`${resquest}/Art/QueryArtistProduce` + params)
  return res.data;
}

// 专家作品详情
export async function queryArtistProduceDetail(params) {
  let res = await http.get(`${resquest}/Art/QueryArtistProduceDetail` + params)
  return res.data;
}

// 轮播图
export async function queryBannerList(params) {
  let res = await http.get(`${resquest}/Common/QueryBannerList` + params)
  return res.data;
}

// 浏览量自动+1
export async function updateNewsReadCount(params) {
  let res = await http.post(`${resquest}/News/UpdateNewsReadCount` + params);
  return res.data;
}