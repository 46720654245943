/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:33 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-We 03:17:10
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1";
let resquest2 = "/api/api";

// 获取艺术展列表
export async function queryArtExhibitionList(params) {
  let res = await http.post(`${resquest}/Art/QueryArtExhibitionList`, params)
  return res.data;
}

// 获取艺术展列表
export async function queryArtExhibitionListNew(params) {
  let res = await http.post(`${resquest}/Art/QueryArtExhibitionListNew`, params)
  return res.data;
}

// 获取艺术展详情
export async function queryArtExhibitionDetail(params) {
  let res = await http.get(`${resquest}/Art/QueryArtExhibitionDetail?id=` + params)
  return res.data;
}

// 获取艺术家详情
export async function querySupplierById(params) {
  let res = await http.get(`${resquest}/Art/QuerySupplierById` + params)
  return res.data;
}

// 艺术展Banner
export async function queryBanners(params) {
  let res = await http.get(`${resquest2}/Shop/ShopBase/QueryBanners`, params)
  return res.data;
}

// 获取最新签约
export async function queryNewestArtist(params) {
  let res = await http.post(`${resquest}/Art/QueryNewestArtist`, params)
  return res.data;
}

// 艺术展列表查询
export async function queryNewestArtExhibition(params) {
  let res = await http.post(`${resquest}/Art/QueryNewestArtExhibition`, params)
  return res.data;
}

// 艺术展列表查询
export async function artWorkAdd(params) {
  let res = await http.post(`${resquest}/Art/ArtWorkAdd`, params)
  return res.data;
}

// 获取场景、类型条件
export async function queryArtProperty(params) {
  let res = await http.get(`${resquest}/Art/QueryArtProperty` + params)
  return res.data;
}

// 获取艺术家
export async function queryArtist(params) {
  let res = await http.get(`${resquest}/Art/queryArtist`, params)
  return res.data;
}
// 获取艺术家
export async function queryArtSize(params) {
  let res = await http.get(`${resquest}/Art/QueryArtSize`, params)
  return res.data;
}
// 获取艺术家
export async function queryArtYear(params) {
  let res = await http.get(`${resquest}/Art/QueryArtYear`, params)
  return res.data;
}

// 获取材质
export async function queryArtMaterial(params) {
  let res = await http.get(`${resquest}/Art/QueryArtMaterial`, params)
  return res.data;
}

// 获取题材
export async function queryArtTheme(params) {
  let res = await http.get(`${resquest}/Art/QueryArtTheme`, params)
  return res.data;
}

// 获取技法
export async function queryArtSkill(params) {
  let res = await http.get(`${resquest}/Art/QueryArtSkill`, params)
  return res.data;
}

// 获取形制
export async function queryArtShape(params) {
  let res = await http.get(`${resquest}/Art/QueryArtShape`, params)
  return res.data;
}

// 艺术家入住
export function artistRegister(params) {
  let res = http.post(`${resquest}/Art/ArtistRegister`, params)
  return res;
}

// 艺术作品收藏
export function collectArtWork(params) {
  let res = http.post(`${resquest}/Art/CollectArtWork`, params)
  return res;
}

// 取消收藏
export function cancleCollectArtWord(params) {
  let res = http.post(`${resquest}/Art/CancleCollectArtWord`, params)
  return res;
}

// 获取我的收藏
export function queryMyCollectArt(params) {
  let res = http.post(`${resquest}/Art/QueryMyCollectArt`, params)
  return res;
}

// 根据学生ID获取艺术家详情
export function querySupplierByStudentId(params) {
  let res = http.get(`${resquest}/Art/QuerySupplierByStudentId` + params)
  return res;
}

// 根据ID获取作品详情
export function queryArtWorkDetail(params) {
  let res = http.get(`${resquest}/Art/QueryArtWorkDetail` + params)
  return res;
}

// 删除作品
export function artWorkDelete(params) {
  let res = http.post(`${resquest}/Art/ArtWorkDelete`+params)
  return res;
}