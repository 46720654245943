<template>
  <div class="authorDetails">
    <div v-if="$route.query.type == 1">
      <div class="top">
        <img :src="teacherInfo.HeadImg" class="headimg" alt="" />
        <h5>
          {{ teacherInfo.SupplierName }}
          <img
            src="@/assets/images/icon_qian.png"
            alt=""
            v-if="teacherInfo.IsSign == 1"
            class="icon_qian"
            srcset=""
          />
        </h5>
      </div>
      <div class="he_10"></div>
      <div class="cantionr">
        <ul class="title_top">
          <li
            :class="typesIndex == i ? ' act' : ''"
            v-for="(m, i) in typesysj"
            :key="i"
            @click="typesClick(i)"
          >
            {{ m }}
          </li>
        </ul>
        <div v-if="typesIndex == 0">
          <div
            class="intr"
            v-if="teacherInfo.Remark"
            v-html="teacherInfo.Remark"
          ></div>
          <div v-else>
            <no-data></no-data>
          </div>
        </div>
        <div class="works" v-if="typesIndex == 1">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getQueryNewestArtExhibition"
            v-if="artList.length > 0"
          >
            <ul>
              <li v-for="(p, i) in artList" :key="i">
                <!-- <img :src="p.CoverImg" alt="" srcset="" @click="preview(p)" /> -->
                <!-- <router-link
                  :to="{
                    path: '/shop/goods/detail/' + p.ArtId,
                  }"
                > -->
                <a
                  :href="
                    '/H5/art/artistsDetails?id=' +
                    p.ArtId +
                    '&artUserId=' +
                    artUserId
                  "
                >
                  <img :src="p.ArtImg" alt="" srcset="" />
                </a>
                <p>{{ p.ArtTitle }}</p>
              </li>
            </ul>
          </van-list>
          <div v-if="artList.length <= 0">
            <no-data></no-data>
          </div>
        </div>
        <div v-if="typesIndex == 2">
          <div
            class="intr"
            v-if="teacherInfo.ExhibitionExperience"
            v-html="teacherInfo.ExhibitionExperience"
          ></div>
          <div v-else>
            <no-data></no-data>
          </div>
        </div>
        <div v-if="typesIndex == 3">
          <div
            class="intr"
            v-if="teacherInfo.AwardExperience"
            v-html="teacherInfo.AwardExperience"
          ></div>
          <div v-else>
            <no-data></no-data>
          </div>
        </div>
        <div v-if="typesIndex == 4">
          <div
            class="intr"
            v-if="teacherInfo.PublishingExperience"
            v-html="teacherInfo.PublishingExperience"
          ></div>
          <div v-else>
            <no-data></no-data>
          </div>
        </div>
        <div v-if="typesIndex == 5">
          <div
            class="intr"
            v-if="teacherInfo.AuctionExperience"
            v-html="teacherInfo.AuctionExperience"
          ></div>
          <div v-else>
            <no-data></no-data>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.query.type == 2">
      <div class="top">
        <img :src="teacherInfo.HeadImg" class="headimg" alt="" />
        <h5>{{ teacherInfo.ArtistName }}</h5>
        <p>{{ teacherInfo.BriefIntroduce }}</p>
      </div>
      <div class="he_10"></div>
      <div class="cantionr">
        <ul class="title_top">
          <li
            :class="typesIndex == i ? ' act' : ''"
            v-for="(m, i) in typeszj"
            :key="i"
            @click="typesClick(i)"
          >
            {{ m }}
          </li>
        </ul>
        <div
          class="intr"
          v-if="typesIndex == 0"
          v-html="teacherInfo.Introduce"
        ></div>
        <div class="works" v-if="typesIndex == 1">
          <ul v-if="produces.length > 0">
            <li v-for="(p, i) in produces" :key="i">
              <!-- <img :src="p.CoverImg" alt="" srcset="" @click="preview(p)" /> -->
              <router-link
                :to="{
                  path: '/H5/fineArts/produceDetail?id=' + p.Id,
                }"
              >
                <img :src="p.CoverImg" alt="" srcset="" />
              </router-link>
              <p class="decimalPoint-2" style="line-height: 1.5">
                {{ p.Title }}
              </p>
            </li>
          </ul>
          <div v-if="produces.length <= 0">
            <no-data></no-data>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview, Dialog } from 'vant';
import { queryArtistDetail, queryArtistProduce } from "@/api/fineArts";
import { querySupplierById, queryNewestArtExhibition } from "@/api/art";
import noData from "@/components/noData";
export default {
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      typesIndex: 0,
      typesysj: ["简介", "作品", "参展经历", "获奖经历", "出版经历", "拍卖记录"],
      typeszj: ["简介", "作品"],
      teacherInfo: {},
      produces: [],
      route: {},
      artList: [],
      loading: false,
      finished: false,
      pageSize: 10,
      pageIndex: 1,
      artUserId: '',
    };
  },
  mounted () {
    this.artUserId = localStorage.getItem("artUserId")
    // type 1艺术家 2专家
    this.route = this.$route.query
    if (this.route.type == 1) {
      document.title = "艺术家"
      this.getQuerySupplierById()
      if (this.route.typesIndex == 1) {
        this.typesIndex = 1
      }
      this.getQueryNewestArtExhibition()
    } else if (this.route.type == 2) {
      document.title = "专家"
      this.getDueryArtistDetail()
      this.getQueryArtistProduce()
    }
  },
  methods: {
    handleAuth () {
      Dialog.confirm({
        title: '先成为实名艺术家',
        message: '如果您想要发表作品，请选申请成为实名艺术家。',
        confirmButtonColor: '#1A93F7',
      })
        .then(() => {
          // on confirm
          this.$router.push("/H5/art/artistRegister");
        })
        .catch(() => {
          // on cancel
        });
    },
    preview (obj) {
      let imgs = this.common.getImgSrc(obj.ProduceIntroduce)
      ImagePreview({
        images: imgs,
        startPosition: 1,
        closeable: true,
      });
    },
    typesClick (i) {
      this.typesIndex = i
    },
    // 艺术家详情
    async getQuerySupplierById () {
      let parm = "?id=" + this.$route.query.id
      const res = await querySupplierById(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
      } else {
        this.$message.error(res.msg);
      }
    },
    // 艺术家作品
    async getQueryNewestArtExhibition () {
      let parm = {
        ArtistId: this.$route.query.id,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }
      const res = await queryNewestArtExhibition(parm);
      this.artList = this.artList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.artList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    // 专家详情
    async getDueryArtistDetail () {
      let parm = "?artistId=" + this.$route.query.id
      const res = await queryArtistDetail(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
        // this.teacherInfo.Name = this.teacherInfo.ArtistName
        // this.teacherInfo.ShortTitle = this.teacherInfo.BriefIntroduce
        // this.teacherInfo.Descript = this.teacherInfo.Introduce
      } else {
        this.$message.error(res.msg);
      }
    },
    // 专家作品
    async getQueryArtistProduce () {
      let parm = "?artistId=" + this.$route.query.id
      const res = await queryArtistProduce(parm);
      if (res.success == true) {
        this.produces = res.response
        this.produces.forEach(element => {
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  }
};
</script>
<style lang="less" scoped>
.authorDetails {
  padding: 0 15px;
  min-height: 100vh;
  background-color: #fff;
  .top {
    text-align: center;
    position: relative;
    .headimg {
      width: 80px;
      height: 80px;
      // background: #e4e4e4;
      border-radius: 50%;
      margin: 30px 0 10px;
    }
    h5 {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 15px;
    }
    .icon_qian {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
    .edit {
      position: absolute;
      top: 10px;
      right: 0;
      i {
        color: #b8b7b7;
        font-size: 20px;
      }
    }
    .auth {
      text-align: center;
      line-height: 1;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 85px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #fff;
        background: #3a78f9;
        border-radius: 20px;
        &::before {
          content: "";
          display: inline-block;
          width: 13.5px;
          height: 14px;
          margin-right: 3px;
          vertical-align: text-bottom;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("../../../assets/images/icon_renzhen.png");
        }
      }
    }
    // p {
    //   color: #6b666b;
    //   text-align: left;
    //   margin-bottom: 20px;
    // }
  }

  .title_top {
    font-size: 15px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    margin: 15px 0 0;
    display: -webkit-box;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    li {
      padding: 0 20px;
    }
    .act {
      color: #3a78f9;
      position: relative;
      font-weight: 800;
      &::before {
        display: inline-block;
        content: " ";
        width: 20px;
        height: 3px;
        background: #3a78f9;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
      }
    }
  }
  .intr {
    color: #6b666b;
    line-height: 1.5;
    padding: 20px;
  }
  .works {
    padding: 20px 0;
    ul {
      display: inline-block;
      -moz-column-count: 2; /* Firefox */
      -webkit-column-count: 2; /* Safari 和 Chrome */
      column-count: 2;
      -moz-column-gap: 15px;
      -webkit-column-gap: 15px;
      column-gap: 15px;
      li {
        break-inside: avoid;
        img {
          width: 100%;
          border-radius: 6px;
        }
      }
    }
  }
  .fixed_auth {
    position: fixed;
    bottom: 40px;
    right: 10px;
  }
}
</style>
